/* App.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::before,
*::after {
  box-sizing: inherit; /* Inherit box-sizing from parent */
}

.area {
  background: #000;
  background: -webkit-linear-gradient(to left, #000, #000);
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 10;
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

.typing-effect {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  background: linear-gradient(to right, #ff7e5f, #feb47b);
  -webkit-background-clip: text;
  color: transparent;
  text-align: center;
  letter-spacing: 1px;
  animation: blink 1s step-end infinite;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}

@keyframes blink {
  0%, 100% {
    border-right-color: transparent;
  }
  50% {
    border-right-color: white;
  }
}

.gradient-text {
  background: linear-gradient(to right, #00c6ff, #0072ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  font-size: 2.5rem; /* Adjust the font size as needed */
  line-height: 1.2;
  letter-spacing: 0.5px;
}

.text-container h1, .text-container h2 {
  white-space: nowrap; /* Prevents text from breaking to a new line */
}

.text-container {
  text-align: left; /* Align text to the left */
}

.left-section {
  padding-right: 2rem; /* Adjust as needed to prevent overlap */
}

.left-section .text-container {
  max-width: 60%; /* Adjust the width as needed */
  word-break: break-word;
}

@media (max-width: 768px) {
  .left-section .text-container {
    max-width: 100%; /* Adjust for smaller screens */
  }
}

.fade-in {
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Add these styles to your CSS file */

.transition-opacity {
  transition: opacity 1s ease-in-out;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.fade-in {
  animation: fadeIn 2s ease-in forwards;
}

.scale-in-center {
  animation: scaleInCenter 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes scaleInCenter {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

:root {
  --primary-color: #ff7e5f; /* Replace with your desired theme color */
}

.text-primary {
  color: var(--primary-color);
}

.bg-primary {
  background-color: var(--primary-color);
}

.gradient-text {
  background: linear-gradient(to right, var(--primary-color), #feb47b);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  font-size: 2.5rem; /* Adjust the font size as needed */
  line-height: 1.2;
  letter-spacing: 0.5px;
  animation: gradient-animation 5s infinite;
}

.animate-gradient {
  animation: gradient-animation 5s ease-in-out infinite;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.text-shadow {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.transition-section {
  height: 50px; /* Adjust height as needed */
  background: linear-gradient(to bottom, #1e3c72, #000000); /* Gradient from blue to black */
}

.gradient-background {
  background: linear-gradient(to bottom, #1e3c72, #2a5298); /* Gradient for Home section */
}

html {
  scroll-behavior: smooth;
}

body, html {
  cursor: default; /* You can set this to 'default' since custom cursor didn't work */
}

.text-2xl {
  font-size: 2rem; /* Adjust the font size for the icons */
}

.text-blue-500 {
  color: #0077b5; /* LinkedIn blue */
}

.text-blue-400 {
  color: #1DA1F2; /* Twitter blue */
}

.text-gray-500 {
  color: #333; /* GitHub gray */
}

.icon-link {
  transition: color 0.3s ease;
  cursor: pointer;
}

.icon-link:hover {
  color: #4b6fb5; /* Change this color to your preferred hover color */
}

.bg-dark-blue {
  background-color: #001f3f; /* Darker blue color */
}

.simple-font {
  font-family: 'Gotham', sans-serif;
  font-size: 2.25rem; /* Adjust size as needed */
  color: white;
  text-transform: uppercase;
}

.blog-updates {
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.blog-updates h2 {
  margin-bottom: 1rem;
  color: white;
  font-size: 1.5rem;
}

.blog-updates .blogs-container {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.blog-updates .blog {
  background-color: white;
  border-radius: 0.5rem;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 18rem;
  height: 12rem;
}

.blog-updates .blog h3 {
  margin-bottom: 0.5rem;
}

.blog-updates .blog p {
  margin-bottom: 0.5rem;
}

.blog-updates .blog a {
  color: #1f77b4;
  text-decoration: none;
}

.left-section {
  padding-right: 2rem; /* Prevent overlap with right section */
}

.left-section .text-container {
  max-width: calc(100% - 2rem); /* Ensure text container does not exceed available space */
  word-break: break-word;
  overflow-wrap: break-word;
}

.left-section h1, .left-section h2 {
  white-space: normal; /* Allow text to wrap */
}

@media (max-width: 768px) {
  .left-section {
    padding-right: 1rem; /* Adjust for smaller screens */
  }
  .left-section .text-container {
    max-width: calc(100% - 1rem); /* Adjust for smaller screens */
  }
}

.right-section {
  padding-left: 8rem; /* Adjust this value to move the section more to the right */
}

.left-section {
  padding-left: 4rem; /* Adjust this value to move the text up or down */
}

.transition-opacity {
  transition: opacity 1s ease-in-out;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.blogs-container {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
}

.left-section {
  padding-top: 1rem; /* Adjust this value to move the text up or down */
}

.text-container {
  text-align: left; /* Align text to the left */
  max-width: 60%; /* Adjust the width as needed */
  word-break: break-word;
}

@media (max-width: 768px) {
  .text-container {
    max-width: 100%; /* Adjust for smaller screens */
  }
}

.transition-opacity {
  transition: opacity 1s ease-in-out;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.blogs-container {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
}

/* Blog Posts Section */
.blog-updates .blogs-container {
  display: flex;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.blog-updates .blog {
  background-color: #1e3c72; /* Change this to match your aesthetic */
  border: 2px solid #4a90e2; /* Border color similar to the projects */
  border-radius: 10px; /* Rounded corners */
  padding: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  width: 18rem;
  height: 12rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Add transition for hover effect */
}

.blog-updates .blog:hover {
  transform: translateY(-5px); /* Move up on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Enhance shadow on hover */
}

.blog-updates .blog h3 {
  margin-bottom: 0.5rem;
  color: #ffffff; /* Text color */
}

.blog-updates .blog p {
  margin-bottom: 0.5rem;
  color: #d1e8ff; /* Text color */
}

.blog-updates .blog a {
  color: #4a90e2;
  text-decoration: none;
  font-weight: bold;
}

.blog-updates .blog a:hover {
  text-decoration: underline; /* Underline on hover */
}

.text-container {
  text-align: left; /* Align text to the left */
  margin-left: 1rem; /* Add left margin */
}

.left-section {
  padding-right: 6rem; /* Adjust as needed to prevent overlap */
}

.blog-updates .blog .truncate-text {
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blog-updates .blog {
  transition: transform 0.6s ease-in-out, opacity 0.6s ease-in-out;
}

.blog-updates .blog.fade-in {
  opacity: 0;
  transform: translateY(20px);
}

.blog-updates .blog.fade-in.show {
  opacity: 1;
  transform: translateY(0);
}

.blog-updates .blogs-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.blog-updates .blog {
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 0.5rem;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 18rem;
  height: auto;
}

.blog-updates .blog .truncate-text {
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blog-updates .blog {
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.blog-updates .blog.fade-in {
  opacity: 0;
  transform: translateY(20px);
}

.blog-updates .blog.fade-in.show {
  opacity: 1;
  transform: translateY(0);
}

.blog-updates .blogs-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.blog-updates .blog {
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 0.5rem;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 18rem;
  height: auto;
}

.blog-updates .blogs-container {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.blog-updates .blog {
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 1rem;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 18rem;
  height: auto;
}

.left-section .text-container {
  max-width: 80%;
  word-break: break-word;
  padding-top: 5rem; /* Adjust this value to move the text down */
}

.text-left {
  text-align: left;
}

.blog-updates .blog .truncate-text {
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blog-updates {
  margin-top: 4rem; /* Adjust this value as needed */
}


.fade-in {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.show {
  opacity: 1;
  transform: translateY(0);
}


.left-section, .right-section, .text-container {
  position: relative;
  z-index: 10;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 2s ease-in forwards;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-in-left {
  animation: slideInFromLeft 1.5s ease-out forwards;
}

/* Fade-in effect for the entire skills section */
.about .skills-container {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeIn 1s ease-in-out forwards;
}

/* Keyframes for the fade-in effect */
@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Hover effect for individual skill items */
.about .skill-item {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.about .skill-item:hover {
  transform: translateY(-5px);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}

/* Further enhancement: background color change on hover */
.about .skill-item:hover {
  background-color: #3d3333;
}

/* Hover effect for project cards */
.project-card {
  transition: transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
}

.project-card:hover {
  transform: scale(1.05); /* Slightly increase the size */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Add a subtle shadow */
}

/* Optional CSS for Navbar */

/* Navbar link hover effect */
.navbar a:hover {
  color: #FFD700; /* Gold color on hover */
}

/* Smooth transition effect for the links */
.navbar a {
  transition: color 0.3s ease-in-out;
}



.right-section {
  width: 30%; /* Make the right section narrower */
  padding-left: 2rem; /* Reduce the left padding */
  padding-right: 2rem; /* Add some padding on the right */
}

.left-section {
  width: 70%; /* Adjust the left section width */
  padding-left: 2rem; /* Reduce the padding on the left */
}

@media (max-width: 768px) {
  .left-section {
    width: 100%; /* Full width for small screens */
    padding-left: 1rem; /* Reduce padding on small screens */
    padding-right: 1rem; /* Add padding on the right */
  }
  .right-section {
    width: 100%; /* Full width for small screens */
    padding-left: 1rem; /* Reduce padding on small screens */
    padding-right: 1rem; /* Add padding on the right */
  }
  .text-container {
    max-width: 100%; /* Full width for small screens */
    padding-right: 1rem; /* Adjust padding for better appearance */
    padding-left: 1rem; /* Adjust padding for better appearance */
  }
}
